import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { PATIENTS, DETAILS, MEDICATIONS_AND_SUPPLEMENTS } from 'constants/routes';
import { API_URL_V2 } from 'env';
import createNotification from 'utils/createNotification';
import request from 'utils/request';

/**
 * Hook for creating patient supplements
 * @returns {Function} Function to create supplements
 */
export const useCreatePatientSupplements = () => {
  const { patientId } = useParams();
  const history = useHistory();

  return React.useCallback(
    (supplements, setIsSubmitting) => {
      setIsSubmitting(true);

      request({
        method: 'post',
        url: '/supplement/batch',
        baseURL: API_URL_V2,
        data: {
          patient_id: patientId,
          supplements,
        },
      })
        .then(() => {
          setIsSubmitting(false);
          createNotification({ message: 'Supplements list successfully created', type: 'success' });
          history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${MEDICATIONS_AND_SUPPLEMENTS}`);
        })
        .catch((error) => {
          setIsSubmitting(false);
          console.error(error);
        });
    },
    [patientId, history],
  );
};

/**
 * Hook for fetching patient supplements
 * @returns {Object} Object containing supplements data, loading state, and fetch function
 */
export const useFetchPatientSupplements = () => {
  const { patientId } = useParams();
  const [supplements, setSupplements] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const fetchSupplements = React.useCallback(async () => {
    if (!patientId) {
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await request({
        method: 'get',
        url: `/supplement?patient_id=${patientId}`,
        baseURL: API_URL_V2,
      });

      setSupplements(response.data.data.Supplements || []);
    } catch (err) {
      setError(err);
      console.error('Error fetching supplements:', err);
    } finally {
      setLoading(false);
    }
  }, [patientId]);

  React.useEffect(() => {
    fetchSupplements();
  }, [fetchSupplements]);

  return { supplements, loading, error, refetch: fetchSupplements };
};

/**
 * Hook for deleting a patient supplement
 * @returns {Function} Function to delete a supplement
 */
export const useDeletePatientSupplement = () => {
  const { patientId } = useParams();

  return React.useCallback(
    async (supplementId) => {
      try {
        await request({
          method: 'delete',
          url: `/supplement/${supplementId}`,
          baseURL: API_URL_V2,
          params: { patient_id: patientId },
        });

        createNotification({ message: 'Supplement successfully deleted', type: 'success' });
        return true;
      } catch (error) {
        console.error('Error deleting supplement:', error);
        createNotification({ message: 'Failed to delete supplement', type: 'error' });
        return false;
      }
    },
    [patientId],
  );
};

/**
 * Hook for updating a patient supplement
 * @returns {Function} Function to update a supplement
 */
export const useUpdatePatientSupplement = () => {
  const { patientId } = useParams();
  const history = useHistory();

  return React.useCallback(
    async (supplementId, supplementData, setIsSubmitting) => {
      if (setIsSubmitting) {
        setIsSubmitting(true);
      }

      try {
        await request({
          method: 'put',
          url: `/supplement/${supplementId}`,
          baseURL: API_URL_V2,
          data: {
            patient_id: patientId,
            ...supplementData,
          },
        });

        createNotification({ message: 'Supplement successfully updated', type: 'success' });
        history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${MEDICATIONS_AND_SUPPLEMENTS}`);
        return true;
      } catch (error) {
        console.error('Error updating supplement:', error);
        createNotification({ message: 'Failed to update supplement', type: 'error' });
        return false;
      } finally {
        if (setIsSubmitting) {
          setIsSubmitting(false);
        }
      }
    },
    [patientId, history],
  );
};
/**
 * Hook for fetching a supplement by ID
 * @returns {Object} Object containing supplement data, loading state, error, and fetch function
 */
export const useFetchSupplementById = () => {
  const { patientId } = useParams();
  const [supplement, setSupplement] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const fetchSupplementById = React.useCallback(
    async (supplementId) => {
      if (!patientId || !supplementId) {
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const response = await request({
          method: 'get',
          url: `/supplement/${supplementId}`,
          baseURL: API_URL_V2,
        });

        setSupplement(response.data.data.supplement || {});
      } catch (err) {
        setError(err);
        console.error('Error fetching supplement by ID:', err);
      } finally {
        setLoading(false);
      }
    },
    [patientId],
  );

  return { supplement, loading, error, fetchSupplementById };
};
