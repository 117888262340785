import { useQuery } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';

import { GET_DEVICE_STATUS } from 'constants/metrics';
import { DeviceStatus } from 'containers/patients/details/metrics/interfaces';
import request from 'utils/request';

interface RouteParams {
  patientId: string;
}

export const useGetDeviceStatus = () => {
  const { patientId } = useParams<RouteParams>();
  return useQuery({
    queryKey: [GET_DEVICE_STATUS, patientId],
    queryFn: () =>
      request({
        method: 'get',
        url: `/patients/${patientId}/devices`,
      }) as Promise<
        AxiosResponse<{
          rpm_member_id: string;
          devices: DeviceStatus[];
        }>
      >,
    enabled: !!patientId,
  });
};
