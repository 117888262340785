export const {
  REACT_APP_API_URL,
  REACT_APP_API_URL_V2,
  REACT_APP_USERPOOL_ID,
  REACT_APP_DISABLE_AUTH,
  REACT_APP_CLIENT_ID,
  REACT_APP_CHAT_API_BASE_URL,
  REACT_APP_PARTICIPANT_API_GRAPHQL_URL,
  REACT_APP_PATIENT_API_GRAPHQL_URL,
  REACT_APP_CURRENT_COUNTRY,
  REACT_APP_PATIENT_MY_HEALTH_API_GRAPHQL_URL,
  REACT_APP_AWS_SOCIAL_LOGIN_DOMAIN,
  REACT_APP_GOOGLE_CALENDAR_CLIENT_ID,
  REACT_APP_NODE_ENV,
  REACT_APP_ADMINS_EMAILS,
  SENTRY_ENV,
  REACT_APP_METABASE_URL,
} = process.env;

export const API_URL = REACT_APP_API_URL || '';
export const API_URL_V2 = REACT_APP_API_URL_V2 || '';
export const USERPOOL_ID = REACT_APP_USERPOOL_ID || '';
export const DISABLE_AUTH = REACT_APP_DISABLE_AUTH || '';
export const CLIENT_ID = REACT_APP_CLIENT_ID || '';
export const CHAT_API_BASE_URL = REACT_APP_CHAT_API_BASE_URL || '';
export const PARTICIPANT_API_GRAPHQL_URL = REACT_APP_PARTICIPANT_API_GRAPHQL_URL || '';
export const PATIENT_API_GRAPHQL_URL = REACT_APP_PATIENT_API_GRAPHQL_URL || '';
export const PATIENT_MY_HEALTH_API_GRAPHQL_URL = REACT_APP_PATIENT_MY_HEALTH_API_GRAPHQL_URL || '';
export const CURRENT_COUNTRY = REACT_APP_CURRENT_COUNTRY || '';
export const AWS_SOCIAL_LOGIN_DOMAIN = REACT_APP_AWS_SOCIAL_LOGIN_DOMAIN || '';
export const GOOGLE_CALENDAR_CLIENT_ID = REACT_APP_GOOGLE_CALENDAR_CLIENT_ID || '';
export const NODE_ENV = REACT_APP_NODE_ENV || '';
export const ADMINS_EMAILS = REACT_APP_ADMINS_EMAILS ? REACT_APP_ADMINS_EMAILS.split(',') : [];
export const SENTRY = SENTRY_ENV || '';
export const METABASE_URL = REACT_APP_METABASE_URL || '';
