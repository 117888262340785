import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import BackButton from 'common/plate/BackButton';
import { FullPlate, WideRowPlate } from 'common/plate/plates';
import { PlateHeader, PlateContent } from 'common/plate/styles';
import TabsSwitcher from 'common/TabsSwitcher';
import {
  PATIENTS,
  ACTIVE,
  DETAILS,
  OVERVIEW,
  SUMMARY,
  PROFILE,
  QUESTIONNAIRES,
  PRE_ASSESSMENT,
  HEALTH_PLANS,
  DOCTOR_NOTES,
  TREATMENT_PLANS,
  DOCUMENTS,
  ADMIN_NOTES,
  INSURANCE,
  WIDGETS,
  FOOD_JOURNAL,
  CARE_JOURNEY,
  MEDICATIONS_AND_SUPPLEMENTS,
  METRICS,
} from 'constants/routes';
import { useUserContext } from 'context/userContext';
import { useFeatureFlags } from 'hooks/useFeatureFlag';
import { useSetPatientProfile, usePatientFullName, useSetPatientFullName } from 'hooks/usePatients';
import { useFetchPatientProfile } from 'requests/patients';

export default function DetailsTemplate({ children, currentTab }) {
  const { patientId } = useParams();
  const ff = useFeatureFlags();
  const { role } = useUserContext();
  const fetchPatientProfile = useFetchPatientProfile();
  const clearPatientProfile = useSetPatientProfile(() => null, []);
  const patientFullName = usePatientFullName();
  const clearPatientFullName = useSetPatientFullName(() => '', []);

  useEffect(() => {
    fetchPatientProfile();
    return clearPatientProfile;
  }, []);

  useEffect(() => {
    return clearPatientFullName;
  }, []);

  const tabsConfig = React.useMemo(() => {
    return [
      {
        id: OVERVIEW,
        roles: ['admin', 'provider'],
        label: 'Overview',
        href: `/${PATIENTS}/${DETAILS}/${patientId}/${OVERVIEW}`,
      },
      {
        id: SUMMARY,
        roles: ['admin', 'provider'],
        label: 'Summary',
        href: `/${PATIENTS}/${DETAILS}/${patientId}/${SUMMARY}`,
      },
      {
        id: PROFILE,
        roles: ['admin', 'provider'],
        label: 'Profile',
        href: `/${PATIENTS}/${DETAILS}/${patientId}/${PROFILE}`,
      },
      {
        id: INSURANCE,
        roles: ['admin'],
        label: 'Insurance ',
        href: `/${PATIENTS}/${DETAILS}/${patientId}/${INSURANCE}`,
      },
      {
        id: QUESTIONNAIRES,
        roles: ['admin', 'provider'],
        label: 'Questionnaires ',
        href: `/${PATIENTS}/${DETAILS}/${patientId}/${QUESTIONNAIRES}/${PRE_ASSESSMENT}`,
      },
      {
        id: DOCTOR_NOTES,
        roles: ['admin', 'provider'],
        label: 'Provider notes',
        href: `/${PATIENTS}/${DETAILS}/${patientId}/${DOCTOR_NOTES}`,
      },
      {
        id: TREATMENT_PLANS,
        roles: ff.treatmentPlan ? ['admin', 'provider'] : [],
        label: 'Treatment plans',
        href: `/${PATIENTS}/${DETAILS}/${patientId}/${TREATMENT_PLANS}`,
      },
      {
        id: HEALTH_PLANS,
        roles: ['admin', 'provider'],
        label: 'Health plan',
        href: `/${PATIENTS}/${DETAILS}/${patientId}/${HEALTH_PLANS}`,
      },
      {
        id: DOCUMENTS,
        roles: ['admin', 'provider'],
        label: 'Documents',
        href: `/${PATIENTS}/${DETAILS}/${patientId}/${DOCUMENTS}`,
      },
      {
        id: ADMIN_NOTES,
        roles: ['admin', 'provider'],
        label: 'Admin notes',
        href: `/${PATIENTS}/${DETAILS}/${patientId}/${ADMIN_NOTES}`,
      },
      {
        id: MEDICATIONS_AND_SUPPLEMENTS,
        roles: ['admin', 'provider'],
        label: 'Medications & Supplements',
        href: `/${PATIENTS}/${DETAILS}/${patientId}/${MEDICATIONS_AND_SUPPLEMENTS}`,
      },
      {
        id: WIDGETS,
        roles: ['admin', 'provider'],
        label: 'RPM ',
        href: `/${PATIENTS}/${DETAILS}/${patientId}/${WIDGETS}`,
      },
      {
        id: METRICS,
        roles: ['admin', 'provider'],
        label: 'Metrics ',
        href: `/${PATIENTS}/${DETAILS}/${patientId}/${METRICS}`,
      },
      {
        id: FOOD_JOURNAL,
        roles: ['provider'],
        label: 'Food Journal ',
        href: `/${PATIENTS}/${DETAILS}/${patientId}/${FOOD_JOURNAL}`,
      },
      {
        id: CARE_JOURNEY,
        roles: ['admin', 'provider'],
        label: 'Care Journey ',
        href: `/${PATIENTS}/${DETAILS}/${patientId}/${CARE_JOURNEY}`,
      },
    ];
  }, [patientId]);

  const Plate = ff.treatmentPlan ? FullPlate : WideRowPlate;
  return (
    <Plate>
      <PlateHeader>
        <BackButton href={`/${PATIENTS}/${ACTIVE}`} />
        {patientFullName}
      </PlateHeader>

      <PlateContent>
        <StyledTabsSwitcher
          className="g-mt-20 g-mb-30"
          tabsConfig={tabsConfig.filter((tab) => tab.roles.includes(role))}
          currentTab={currentTab}
        />

        {children}
      </PlateContent>
    </Plate>
  );
}

const StyledTabsSwitcher = styled(TabsSwitcher)`
  a {
    min-width: auto;
    padding-right: 0.55rem;
    padding-left: 0.55rem;
  }
`;
