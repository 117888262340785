import dexcomLogo from 'assets/new-rpm/dexcom-logo.svg';
import fitbitLogo from 'assets/new-rpm/fitbit-logo.svg';
import withingsLogo from 'assets/new-rpm/withings-logo.svg';
import { isProductionEnv } from 'utils/env/envUtils';

import { DeviceType, IDevicesConfig } from '../interfaces';

export const devicesConfig: IDevicesConfig = {
  withings: [
    { key: 'withings-weight-scale', label: 'Weight Scale' },
    { key: 'withings-blood-pressure', label: 'Blood Pressure' },
    { key: 'withings-heart-rate', label: 'Heart Rate' },
  ],
  fitbit: [
    { key: 'activity-log', label: 'Activity Log and Level' },
    { key: 'sleep-score', label: 'Sleep Score' },
  ],
  dexcom: [
    { key: 'dexcom-glucose-levels', label: 'Glucose Levels' },
    { key: 'dexcom-alerts', label: 'Alerts' },
  ],
};

export const enabledDevices: DeviceType[] = isProductionEnv()
  ? [DeviceType.WITHINGS, DeviceType.FITBIT]
  : [DeviceType.WITHINGS, DeviceType.FITBIT, DeviceType.DEXCOM];

export const devicesToFetchStatus: DeviceType[] = isProductionEnv()
  ? [DeviceType.WITHINGS, DeviceType.FITBIT]
  : [DeviceType.WITHINGS, DeviceType.FITBIT, DeviceType.DEXCOM];

interface IDeviceInfo {
  logo: string;
}

export const deviceInfo: Record<DeviceType, IDeviceInfo> = {
  [DeviceType.WITHINGS]: {
    logo: withingsLogo,
  },
  [DeviceType.FITBIT]: {
    logo: fitbitLogo,
  },
  [DeviceType.DEXCOM]: {
    logo: dexcomLogo,
  },
};
