import moment from 'moment';
import { useReducer, useCallback } from 'react';

export type FormErrors<T> = Record<number, Partial<Record<keyof T, string>>>;

export interface UseMultiItemFormOptions<T> {
  initialData: T;
  validateItem?: (item: T, index: number, items: T[]) => Partial<Record<keyof T, string>>;
}

type Action<T> =
  | { type: 'SET_ITEMS'; payload: T[] }
  | { type: 'SET_ERRORS'; payload: FormErrors<T> }
  | { type: 'RESET'; payload: T }
  | { type: 'SET_ITEM'; index: number; field: keyof T; value: T[keyof T] };

const formReducer = <T>(state: { items: T[]; errors: FormErrors<T> }, action: Action<T>) => {
  switch (action.type) {
    case 'SET_ITEMS':
      return { ...state, items: action.payload };
    case 'SET_ERRORS':
      return { ...state, errors: action.payload };
    case 'RESET':
      return { items: [{ ...action.payload }], errors: {} };
    case 'SET_ITEM':
      return {
        ...state,
        items: state.items.map((item, i) =>
          i === action.index ? { ...item, [action.field]: action.value } : item,
        ),
        errors: {
          ...state.errors,
          [action.index]: {
            ...state.errors[action.index],
            [action.field]: undefined,
          },
        },
      };
    default:
      return state;
  }
};

export function useMultiItemForm<T>({ initialData, validateItem }: UseMultiItemFormOptions<T>) {
  const [state, dispatch] = useReducer(formReducer<T>, {
    items: [{ ...initialData }],
    errors: {},
  });

  const handleChange = useCallback(
    <K extends keyof T>(index: number, field: K) =>
      (value: T[K]) => {
        dispatch({ type: 'SET_ITEM', index, field, value });
      },
    [],
  );

  const handleDateChange = useCallback(
    (index: number, field: keyof T) => (date: string | null) => {
      const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
      dispatch({ type: 'SET_ITEM', index, field, value: formattedDate as T[typeof field] });
    },
    [],
  );

  const handleDelete = useCallback(
    (index: number) => {
      dispatch({
        type: 'SET_ITEMS',
        payload: state.items.filter((_, i) => i !== index),
      });
    },
    [state.items],
  );

  const validateForm = useCallback((): boolean => {
    if (!validateItem) {
      return true;
    }

    const newErrors: FormErrors<T> = state.items.reduce((acc, item, index) => {
      const rowErrors = validateItem(item, index, state.items);
      if (Object.keys(rowErrors).length > 0) {
        acc[index] = rowErrors;
      }
      return acc;
    }, {} as FormErrors<T>);

    dispatch({ type: 'SET_ERRORS', payload: newErrors });
    return Object.keys(newErrors).length === 0;
  }, [state.items, validateItem]);

  const handleAddAnother = useCallback(() => {
    dispatch({
      type: 'SET_ITEMS',
      payload: [...state.items, { ...initialData }],
    });
  }, [state.items, initialData]);

  const resetForm = useCallback(() => {
    dispatch({ type: 'RESET', payload: initialData });
  }, [initialData]);

  const setItems = useCallback((newItems: T[]) => {
    dispatch({ type: 'SET_ITEMS', payload: newItems });
  }, []);

  return {
    items: state.items,
    errors: state.errors,
    handleChange,
    handleDateChange,
    handleDelete,
    validateForm,
    handleAddAnother,
    resetForm,
    setItems,
    hasMoreThanOneItem: state.items.length > 1,
  };
}
