import React, { useEffect, useMemo } from 'react';

import Spinner from 'common/loaders/Spinner';
import Button from 'components/new/Button';
import { METABASE_URL } from 'env';
import { useGetDeviceStatus } from 'requests/patients/metrics';
import createNotification from 'utils/createNotification';
import { openUrlAsync } from 'utils/openUrlAsync';

import { DeviceCard } from '../DeviceCard';
import { devicesConfig, enabledDevices } from './config';
import styles from './index.module.css';
import { IDevicesConfig } from '../interfaces';

export const Accounts = () => {
  const { isLoading, data, error } = useGetDeviceStatus();

  const memoizedDeviceFields = useMemo(
    () =>
      enabledDevices.map((device) => ({
        device,
        fields: devicesConfig[device.toLowerCase() as keyof IDevicesConfig],
      })),
    [enabledDevices, devicesConfig],
  );

  useEffect(() => {
    if (error) {
      createNotification({
        title: 'Error',
        message: 'Failed to fetch device status',
        type: 'error',
      });
    }
  }, [error]);

  if (isLoading || !data) {
    return (
      <div className={styles.loadingContainer}>
        <Spinner />
      </div>
    );
  }

  const handleRpmDetailsClick = () => {
    const rpmMemberId = data.data.rpm_member_id;
    const url = `${METABASE_URL}?id=${rpmMemberId}`;
    openUrlAsync(Promise.resolve(url));
  };

  return (
    <>
      <div className={styles.title}>
        <h3>RPM Metrics</h3>
        <Button onClick={handleRpmDetailsClick} disabled={false} text="RPM Details" size="medium" />
      </div>

      <div>
        {data.data.devices.map(({ device, healthy, connected, last_updated }) => {
          const fields = memoizedDeviceFields.find((field) => field.device === device)?.fields;
          if (!fields) {
            return null;
          }
          return (
            <DeviceCard
              key={device}
              type={device}
              deviceFields={fields}
              isHealthyConnection={healthy}
              isConnected={connected}
              lastUpdated={last_updated}
            />
          );
        })}
      </div>
    </>
  );
};
