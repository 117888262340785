import React from 'react';
import { useParams } from 'react-router-dom';

import BackButton from 'common/plate/BackButton';
import { PlateHeader } from 'common/plate/styles';
import { PATIENTS, DETAILS, MEDICATIONS_AND_SUPPLEMENTS } from 'constants/routes';

export const MedicationsAndSupplementsHeader = () => {
  const { patientId } = useParams<{ patientId: string }>();

  return (
    <PlateHeader>
      <BackButton href={`/${PATIENTS}/${DETAILS}/${patientId}/${MEDICATIONS_AND_SUPPLEMENTS}`} />
      <h1>Medications & Supplements</h1>
    </PlateHeader>
  );
};
