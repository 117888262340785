import React from 'react';

import { GenericEditForm } from 'containers/patients/details/medications-and-supplements/GenericEditForm';
import { SupplementData } from 'hooks/patient/medications-and-supplements/creation/useSupplementForm';
import { useSingleSupplementForm } from 'hooks/patient/medications-and-supplements/edition/useSingleSupplementForm';

import { SupplementFormFields } from './SupplementFormFields';

interface EditSupplementFormProps {
  initialSupplement: SupplementData;
  onSubmit: (supplement: SupplementData) => void;
  onCancel: () => void;
  isSubmitting?: boolean;
}

export const EditSupplementForm: React.FC<EditSupplementFormProps> = ({
  initialSupplement,
  onSubmit,
  onCancel,
  isSubmitting = false,
}) => (
  <GenericEditForm
    title="Edit Supplement"
    initialData={initialSupplement}
    onSubmit={onSubmit}
    onCancel={onCancel}
    isSubmitting={isSubmitting}
    useFormHook={useSingleSupplementForm}
    renderFields={({ data, errors, handleChange, handleDateChange, isSubmitting }) => (
      <SupplementFormFields
        supplement={data}
        errors={errors}
        handleChange={handleChange}
        handleDateChange={handleDateChange}
        isSubmitting={isSubmitting}
      />
    )}
  />
);
