import {
  useGenericForm,
  validateDateRange,
} from 'hooks/patient/medications-and-supplements/creation/useGenericForm';

export interface MedicationData {
  name: string;
  dosage: string;
  units: string;
  form: string;
  started_at: string;
  ended_at: string;
  signetur: string;
}

export const initialMedicationData: MedicationData = {
  name: '',
  dosage: '',
  units: '',
  form: '',
  started_at: '',
  ended_at: '',
  signetur: '',
};

export const useMedicationForm = () => {
  const validateMedication = (med: MedicationData) => {
    const rowErrors: Partial<Record<keyof MedicationData, string>> = {};

    if (!med.name || med.name.trim() === '') {
      rowErrors.name = 'Medication name is required';
    }
    if (!med.dosage || med.dosage.trim() === '') {
      rowErrors.dosage = 'Dosage is required';
    }
    if (!med.units || med.units.trim() === '') {
      rowErrors.units = 'Units are required';
    }
    const dateError = validateDateRange(med.started_at, med.ended_at);
    if (dateError) {
      rowErrors.ended_at = dateError;
    }
    return rowErrors;
  };

  const formHook = useGenericForm<MedicationData>(initialMedicationData, validateMedication);

  return {
    ...formHook,
    medications: formHook.items || [],
    hasMoreThanOneMedication: formHook.hasMoreThanOne || false,
  };
};
