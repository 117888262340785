import React, { useState } from 'react';

import { DeleteModal } from 'components/new/DeleteModal/DeleteModal';
import { MedSupplementItemCard } from 'containers/patients/details/medications-and-supplements/components/MedSupplementItemCard';
import styles from 'containers/patients/details/medications-and-supplements/supplements/SupplementsList.module.css';
import { SupplementType } from 'containers/patients/details/medications-and-supplements/types';

interface SupplementsListProps {
  supplements: SupplementType[];
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
}

export const SupplementsList: React.FC<SupplementsListProps> = ({
  supplements,
  onEdit,
  onDelete,
}) => {
  const [supplementToDelete, setSupplementToDelete] = useState<number | null>(null);

  if (!supplements?.length) {
    return (
      <div className={styles.sectionContainer}>
        <h2 className={styles.sectionTitle}>Supplements</h2>
        <div className={styles.emptyState}>No supplements found</div>
      </div>
    );
  }

  const handleDeleteClick = (id: number) => {
    setSupplementToDelete(id);
  };

  const handleConfirmDelete = () => {
    if (supplementToDelete !== null) {
      onDelete(supplementToDelete);
      setSupplementToDelete(null);
    }
  };

  const handleCancelDelete = () => {
    setSupplementToDelete(null);
  };

  return (
    <div className={styles.sectionContainer}>
      <div className={styles.listContainer}>
        {supplements.map((supplement) => (
          <MedSupplementItemCard
            key={supplement.id}
            item={{
              id: supplement.id,
              name: supplement.name,
              dates: {
                startDate: supplement.started_at,
                endDate: supplement.ended_at,
              },
              signetur: supplement.signetur,
            }}
            onEdit={() => onEdit(supplement.id)}
            onDelete={() => handleDeleteClick(supplement.id)}
          />
        ))}
      </div>
      {supplementToDelete !== null && (
        <DeleteModal
          itemType="Supplement"
          description="Please confirm you want to delete this supplement from the list"
          onDelete={handleConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}
    </div>
  );
};
