import React from 'react';

import DetailsTemplate from 'components/patients/DetailsTemplate';
import { METRICS } from 'constants/routes';

import { Accounts } from './Accounts';

export const PatientMetrics = () => {
  return (
    <DetailsTemplate currentTab={METRICS}>
      <Accounts />
    </DetailsTemplate>
  );
};
