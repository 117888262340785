import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import { UppercaseText } from 'common/texts';

import CalendarInput from './CalendarInput';
import CalendarWindow from './CalendarWindow';

const CustomCalendar = React.memo(function CustomCalendar({
  className = '',
  isLoading,
  label,
  minDate,
  selectedDate,
  setSelectedDate,
  isRequired,
  isValid,
  position = 'default',
  size = 'small',
  dateSeparator = '.',
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  const ref = React.useRef();

  const closeHandler = React.useCallback(
    (e) => {
      if (ref.current && ref.current.contains(e.target)) {
        return;
      }
      setIsOpen(false);
    },
    [ref, setIsOpen],
  );

  useEffect(() => {
    window.addEventListener('click', closeHandler);
    return () => window.removeEventListener('click', closeHandler);
  }, [closeHandler]);

  if (isLoading) {
    return (
      <StyledLoader className={className}>
        {label && <div className="loader-label" />}
        <div className="loader-input" />
      </StyledLoader>
    );
  }

  return (
    <StyledWrapper className={className} ref={ref}>
      {label && <UppercaseText className="g-mb-10">{label}</UppercaseText>}
      <CalendarInput
        isRequired={isRequired}
        isValid={isValid}
        value={selectedDate}
        setIsOpen={setIsOpen}
        size={size}
        dateSeparator={dateSeparator}
      />
      {isOpen && (
        <CalendarWindow
          minDate={minDate}
          setIsOpen={setIsOpen}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          position={position}
        />
      )}
    </StyledWrapper>
  );
});

CustomCalendar.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  dateSeparator: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  selectedDate: PropTypes.string,
  setSelectedDate: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  isValid: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  position: PropTypes.oneOf(['default', 'left', 'right']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default CustomCalendar;

const StyledLoader = styled.div`
  max-width: 27rem;
  width: 100%;
  @media only screen and (max-width: 480px) {
    max-width: initial;
  }

  .loader-label {
    height: 1.8rem;
    width: 50%;
    margin-bottom: 1rem;
    background: #e9ebef;
  }

  .loader-input {
    height: 4.8rem;
    background: #e9ebef;
  }
`;

const StyledWrapper = styled.div`
  position: relative;
  max-width: 27rem;
  width: 100%;
  @media only screen and (max-width: 480px) {
    max-width: initial;
  }
`;
