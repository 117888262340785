import classNames from 'classnames';
import React from 'react';

import Button from 'components/new/Button';
import { Icon } from 'components/new/Icon/Icon';
import { Input } from 'components/new/Input';
import { DatePickerField } from 'containers/patients/details/medications-and-supplements/components/DatePickerField';
import {
  useSupplementForm,
  SupplementData,
} from 'hooks/patient/medications-and-supplements/creation/useSupplementForm';

import styles from './SupplementForm.module.css';

interface SupplementFormProps {
  onSubmit: (supplements: SupplementData[]) => void;
  className?: string;
  isSubmitting?: boolean;
}

export const SupplementForm: React.FC<SupplementFormProps> = ({
  onSubmit,
  className,
  isSubmitting,
}) => {
  const {
    supplements,
    errors,
    handleChange,
    handleDateChange,
    handleDelete,
    validateForm,
    handleAddAnother,
    resetForm,
    hasMoreThanOneSupplement,
  } = useSupplementForm();

  const getErrorForField = (index: number, field: string) => errors[index]?.[field];

  const handleSubmit = () => {
    if (validateForm()) {
      onSubmit(supplements);
      resetForm();
    }
  };

  return (
    <>
      <h2 className={styles.title}>New Supplement</h2>

      <div
        className={classNames(
          styles.wrapper,
          { [styles.spaceAbove]: !hasMoreThanOneSupplement },
          className,
        )}
      >
        {supplements.map((supplement, index) => {
          return (
            <div key={index} className={styles.supplementRow}>
              {hasMoreThanOneSupplement && (
                <button
                  className={styles.closeIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete(index);
                  }}
                  disabled={isSubmitting}
                >
                  <Icon name="close" height={28} width={28} />
                </button>
              )}
              <div className={styles.formRow}>
                <div className={styles.inputWrapper}>
                  <Input
                    label="SUPPLEMENT NAME"
                    placeholder="Type a supplement name"
                    value={supplement.name}
                    changeHandler={handleChange(index, 'name')}
                    isRequired={true}
                    error={getErrorForField(index, 'name')}
                    disabled={isSubmitting}
                  />
                </div>

                <div className={styles.dateInputWrapper}>
                  <DatePickerField
                    className={styles.dateInput}
                    label="START DATE"
                    value={
                      supplement.started_at ? new Date(supplement.started_at).toISOString() : null
                    }
                    onChange={(date) => handleDateChange(index, 'started_at')(date)}
                    minDate={null}
                  />
                </div>

                <div className={styles.dateInputWrapper}>
                  <DatePickerField
                    className={styles.dateInput}
                    label="END DATE"
                    value={supplement.ended_at ? new Date(supplement.ended_at).toISOString() : null}
                    onChange={(date) => handleDateChange(index, 'ended_at')(date)}
                    error={getErrorForField(index, 'ended_at')}
                    minDate={supplement.started_at ? new Date(supplement.started_at) : undefined}
                  />
                </div>
              </div>

              <div className={styles.sigInput}>
                <Input
                  label="SIG"
                  placeholder=""
                  value={supplement.signetur}
                  changeHandler={handleChange(index, 'signetur')}
                  disabled={isSubmitting}
                />
              </div>
            </div>
          );
        })}

        <div className={styles.buttonsContainer}>
          <Button
            text={isSubmitting ? 'Submitting...' : 'Submit All'}
            onClick={handleSubmit}
            variant="primary-purple"
            className={styles.addButton}
            disabled={isSubmitting}
          />

          <Button
            text="Add Another Supplement"
            onClick={handleAddAnother}
            variant="secondary-purple"
            disabled={isSubmitting}
          />
        </div>
      </div>
    </>
  );
};
