import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { PATIENTS, DETAILS, MEDICATIONS_AND_SUPPLEMENTS } from 'constants/routes';
import { API_URL_V2 } from 'env';
import createNotification from 'utils/createNotification';
import request from 'utils/request';

/**
 * Hook for creating patient medications
 * @returns {Function} Function to create medications
 */
export const useCreatePatientMedicationsV2 = () => {
  const { patientId } = useParams();
  const history = useHistory();

  return React.useCallback(
    (medications, setIsSubmitting) => {
      setIsSubmitting(true);

      request({
        method: 'post',
        url: '/medication/batch',
        baseURL: API_URL_V2,
        data: {
          patient_id: patientId,
          medications,
        },
      })
        .then(() => {
          setIsSubmitting(false);
          createNotification({ message: 'Medications list successfully created', type: 'success' });
          history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${MEDICATIONS_AND_SUPPLEMENTS}`);
        })
        .catch((error) => {
          setIsSubmitting(false);
          console.error(error);
        });
    },
    [patientId, history],
  );
};

/**
 * Hook for fetching patient medications
 * @returns {Object} Object containing medications data, loading state, and fetch function
 */
export const useFetchPatientMedications = () => {
  const { patientId } = useParams();
  const [medications, setMedications] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const fetchMedications = React.useCallback(async () => {
    if (!patientId) {
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await request({
        method: 'get',
        url: `/medication?patient_id=${patientId}`,
        baseURL: API_URL_V2,
      });

      setMedications(response.data.data.medications || []);
    } catch (err) {
      setError(err);
      console.error('Error fetching medications:', err);
    } finally {
      setLoading(false);
    }
  }, [patientId]);

  React.useEffect(() => {
    fetchMedications();
  }, [fetchMedications]);

  return { medications, loading, error, refetch: fetchMedications };
};
/**
 * Hook for fetching a medication by ID
 * @returns {Object} Object containing medication, loading state, error and fetch function
 */
export const useFetchMedicationById = () => {
  const { patientId } = useParams();
  const [medication, setMedication] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const fetchMedicationById = React.useCallback(
    async (medicationId) => {
      if (!patientId || !medicationId) {
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const response = await request({
          method: 'get',
          url: `/medication/${medicationId}`,
          baseURL: API_URL_V2,
        });

        setMedication(response.data.data.medication || {});
      } catch (err) {
        setError(err);
        console.error('Error fetching medication by ID:', err);
      } finally {
        setLoading(false);
      }
    },
    [patientId],
  );

  return { medication, loading, error, fetchMedicationById };
};

/**
 * Hook for deleting a patient medication
 * @returns {Function} Function to delete a medication
 */
export const useDeletePatientMedication = () => {
  const { patientId } = useParams();

  return React.useCallback(
    async (medicationId) => {
      try {
        await request({
          method: 'delete',
          url: `/medication/${medicationId}`,
          baseURL: API_URL_V2,
          params: { patient_id: patientId },
        });

        createNotification({ message: 'Medication successfully deleted', type: 'success' });
        return true;
      } catch (error) {
        console.error('Error deleting medication:', error);
        createNotification({ message: 'Failed to delete medication', type: 'error' });
        return false;
      }
    },
    [patientId],
  );
};

/**
 * Hook for updating a patient medication
 * @returns {Function} Function to update a medication
 */
export const useUpdatePatientMedication = () => {
  const { patientId } = useParams();
  const history = useHistory();

  return React.useCallback(
    async (medicationId, medicationData, setIsSubmitting) => {
      if (setIsSubmitting) {
        setIsSubmitting(true);
      }

      try {
        await request({
          method: 'put',
          url: `/medication/${medicationId}`,
          baseURL: API_URL_V2,
          data: {
            patient_id: patientId,
            ...medicationData,
          },
        });

        createNotification({ message: 'Medication successfully updated', type: 'success' });
        history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${MEDICATIONS_AND_SUPPLEMENTS}`);
      } catch (error) {
        console.error('Error updating medication:', error);
        createNotification({ message: 'Failed to update medication', type: 'error' });
      } finally {
        setIsSubmitting(false);
      }
    },
    [patientId, history],
  );
};
