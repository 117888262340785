import {
  initialMedicationData,
  MedicationData,
  useMedicationForm,
} from 'hooks/patient/medications-and-supplements/creation/useMedicationForm';
import {
  createSingleFormHook,
  UseMultipleItemsReturn,
} from 'hooks/patient/medications-and-supplements/edition/useSingleForm';

export const useSingleMedicationForm = createSingleFormHook<MedicationData>(
  useMedicationForm as () => UseMultipleItemsReturn<MedicationData>,
  initialMedicationData,
);
