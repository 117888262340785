import React, { useMemo } from 'react';

import { ThreeDotMenu } from 'components/new/ThreeDotMenu/ThreeDotMenu';
import MedicationHeader from 'components/patients/medications/common/MedicationHeader';
import { extractDate } from 'utils/patients/utils';

import styles from './MedSupplementItemCard.module.css';

interface ItemProps {
  id: number;
  name: string;
  dates?: {
    startDate?: string;
    endDate?: string;
  };
  dosage?: string;
  signetur: string;
  units?: string;
}

interface ItemCardProps {
  item: ItemProps;
  onEdit: () => void;
  onDelete: () => void;
}

export const MedSupplementItemCard: React.FC<ItemCardProps> = ({ item, onEdit, onDelete }) => {
  const { dates = {}, dosage = '', name, signetur, units = '' } = item;
  const menuOptions = useMemo(
    () => [
      { label: 'Edit', onClick: onEdit },
      { label: 'Delete', onClick: onDelete, red: true },
    ],
    [onEdit, onDelete],
  );

  const startDate = extractDate(dates.startDate);
  const endDate = extractDate(dates.endDate);
  return (
    <div className={styles.card}>
      <div className={styles.cardContent}>
        <div className={styles.detailsContainer}>
          <MedicationHeader icon="capsule" medicationName={name} description="" />
          <div className={styles.detailItem}>
            <span className={styles.detailLabel}>{dosage && 'Dosage'}</span>
            <span className={styles.detailValue}>{`${dosage} ${units}`}</span>
          </div>
          {dates && (
            <div className={styles.datesContainer}>
              <div className={styles.detailItem}>
                <span className={styles.detailLabel}>Start Date</span>
                <span className={styles.detailValue}>{startDate}</span>
              </div>
              <div className={styles.detailItem}>
                <span className={styles.detailLabel}>End Date</span>
                <span className={styles.detailValue}>{endDate}</span>
              </div>
            </div>
          )}
        </div>
        <div className={styles.menuContainer}>
          <ThreeDotMenu options={menuOptions} />
        </div>
      </div>
      {signetur && (
        <div className={styles.signeturContainer}>
          <span className={styles.detailLabel}>SIG</span>
          <p className={styles.detailValue}>{signetur}</p>
        </div>
      )}
    </div>
  );
};
