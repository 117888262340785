import classNames from 'classnames';
import React from 'react';

import Button from 'components/new/Button';
import { Icon } from 'components/new/Icon/Icon';
import {
  useMedicationForm,
  MedicationData,
} from 'hooks/patient/medications-and-supplements/creation/useMedicationForm';

import styles from './MedicationForm.module.css';
import { MedicationFormFields } from './MedicationFormFields';

interface MedicationFormProps {
  onSubmit: (medications: MedicationData[]) => void;
  className?: string;
  isSubmitting?: boolean;
}

export const MedicationForm: React.FC<MedicationFormProps> = ({
  onSubmit,
  className,
  isSubmitting = false,
}) => {
  const {
    medications,
    errors,
    handleChange,
    handleDateChange,
    handleDelete,
    validateForm,
    handleAddAnother,
    resetForm,
    hasMoreThanOneMedication,
  } = useMedicationForm();

  const handleSubmit = () => {
    if (validateForm()) {
      onSubmit(medications);
      resetForm();
    }
  };

  return (
    <>
      <h2 className={styles.title}>New Medication</h2>
      <div
        className={classNames(
          styles.wrapper,
          { [styles.spaceAbove]: !hasMoreThanOneMedication },
          className,
        )}
      >
        {medications.map((medication, index) => (
          <div key={index} className={styles.medicationRow}>
            {hasMoreThanOneMedication && (
              <button
                className={styles.closeIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelete(index);
                }}
                disabled={isSubmitting}
              >
                <Icon name="close" height={28} width={28} />
              </button>
            )}
            <MedicationFormFields
              medication={medication}
              errors={errors[index] || {}}
              handleChange={(field: string) => (value) =>
                handleChange(index, field as keyof MedicationData)(value)
              }
              handleDateChange={(field) => (date) => handleDateChange(index, field)(date)}
              isSubmitting={isSubmitting}
            />
          </div>
        ))}

        <div className={styles.buttonsContainer}>
          <Button
            text={isSubmitting ? 'Submitting...' : 'Submit All'}
            onClick={handleSubmit}
            variant="primary-purple"
            className={styles.addButton}
            disabled={isSubmitting}
          />
          <Button
            text="Add Another Medication"
            onClick={handleAddAnother}
            variant="secondary-purple"
            disabled={isSubmitting}
          />
        </div>
      </div>
    </>
  );
};
