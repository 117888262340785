import React from 'react';

import { RowPlate } from 'common/plate/plates';
import { PlateContent } from 'common/plate/styles';
import { MedicationsAndSupplementsHeader } from 'containers/patients/details/medications-and-supplements/components/Header';
import { SupplementForm } from 'containers/patients/details/medications-and-supplements/supplements/SupplementForm';
import { useCreatePatientSupplements } from 'requests/patients/supplements';

export const CreateSupplements = () => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const createSupplements = useCreatePatientSupplements();

  const handleSubmit = (formData) => {
    createSupplements(formData, setIsSubmitting);
  };

  return (
    <RowPlate>
      <MedicationsAndSupplementsHeader />
      <PlateContent>
        <SupplementForm onSubmit={handleSubmit} isSubmitting={isSubmitting} />
      </PlateContent>
    </RowPlate>
  );
};
