import moment from 'moment';
import { useCallback, useEffect } from 'react';

export interface UseMultipleItemsReturn<T> {
  items: T[];
  errors: Record<number, Partial<Record<keyof T, string>>>;
  handleChange: (index: number, field: string) => (value: string) => void;
  handleDateChange: (index: number, field: keyof T) => (date: string | null) => void;
  validateForm: () => boolean;
  setItems: (items: T[]) => void;
  resetForm: () => void;
}

export function createSingleFormHook<T>(
  useMultipleItemsHook: () => UseMultipleItemsReturn<T>,
  defaultData: T,
) {
  return function useSingleForm(initialData: T = defaultData) {
    const { items, errors, handleChange, handleDateChange, validateForm, setItems, resetForm } =
      useMultipleItemsHook();

    useEffect(() => {
      setItems([initialData]);
    }, [initialData, setItems]);

    const handleSingleChange = useCallback(
      (field: string) => (value: string) => {
        handleChange(0, field)(value);
      },
      [handleChange],
    );

    const handleSingleDateChange = useCallback(
      (field: keyof T) => (date: string | null) => {
        const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
        handleDateChange(0, field)(formattedDate || null);
      },
      [handleDateChange],
    );

    const validateSingleForm = useCallback(() => validateForm(), [validateForm]);

    const resetSingleForm = useCallback(() => resetForm(), [resetForm]);

    const setSingleItem = useCallback(
      (newItem: T) => {
        setItems([newItem]);
      },
      [setItems],
    );

    return {
      item: items[0] || initialData,
      errors: errors[0] || {},
      handleChange: handleSingleChange,
      handleDateChange: handleSingleDateChange,
      validateForm: validateSingleForm,
      resetForm: resetSingleForm,
      setItem: setSingleItem,
    };
  };
}
