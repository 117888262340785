import React from 'react';

import { RowPlate } from 'common/plate/plates';
import { PlateContent } from 'common/plate/styles';
import { MedicationsAndSupplementsHeader } from 'containers/patients/details/medications-and-supplements/components/Header';
import { MedicationForm } from 'containers/patients/details/medications-and-supplements/medications/MedicationForm';
import { useCreatePatientMedicationsV2 } from 'requests/patients/medications';

export const CreateMedications = () => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const createMedications = useCreatePatientMedicationsV2();

  const handleSubmit = (formData) => {
    createMedications(formData, setIsSubmitting);
  };

  return (
    <RowPlate>
      <MedicationsAndSupplementsHeader />
      <PlateContent>
        <MedicationForm onSubmit={handleSubmit} isSubmitting={isSubmitting} />
      </PlateContent>
    </RowPlate>
  );
};
