import {
  useGenericForm,
  validateDateRange,
} from 'hooks/patient/medications-and-supplements/creation/useGenericForm';

export interface SupplementData {
  name: string;
  started_at: string;
  ended_at: string;
  signetur: string;
}

export const initialSupplementData: SupplementData = {
  name: '',
  started_at: '',
  ended_at: '',
  signetur: '',
};

export const useSupplementForm = () => {
  const validateSupplement = (supplement: SupplementData) => {
    const rowErrors: Partial<Record<keyof SupplementData, string>> = {};

    if (!supplement.name || supplement.name.trim() === '') {
      rowErrors.name = 'Supplement name is required';
    }
    const dateError = validateDateRange(supplement.started_at, supplement.ended_at);
    if (dateError) {
      rowErrors.ended_at = dateError;
    }
    return rowErrors;
  };

  const formHook = useGenericForm<SupplementData>(initialSupplementData, validateSupplement);

  return {
    ...formHook,
    supplements: formHook.items,
    hasMoreThanOneSupplement: formHook.hasMoreThanOne,
  };
};
