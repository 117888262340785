import classNames from 'classnames';
import React from 'react';

import './index.css';

export interface ButtonProps {
  variant?:
    | 'primary-purple'
    | 'secondary-red'
    | 'primary-green'
    | 'secondary-green'
    | 'secondary-purple';
  text: string;
  onClick: () => void;
  size?: 'small' | 'medium';
  fullWidth?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  className?: string;
}

const Button: React.FC<ButtonProps> = ({
  className,
  variant = 'primary-purple',
  text,
  onClick,
  size = 'medium',
  fullWidth,
  isLoading = false,
  disabled = false,
}) => {
  return (
    <button
      className={classNames('button', variant, size, { 'full-width': fullWidth }, className)}
      onClick={onClick}
      disabled={disabled || isLoading}
    >
      {isLoading ? <span className="spinner"></span> : text}
    </button>
  );
};

export default Button;
