import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Calendar } from 'assets/calendar.svg';

const CalendarInput = React.memo(function CalendarInput({
  isRequired,
  isValid,
  value,
  setIsOpen,
  size = 'small',
  dateSeparator = '.',
}) {
  const placeholder = (
    <span className="placeholder">
      mm{dateSeparator}dd{dateSeparator}yyyy
    </span>
  );

  const formatDate = (date) => {
    if (!date) {
      return null;
    }
    return moment(date).local().format(`MM${dateSeparator}DD${dateSeparator}YYYY`);
  };

  return (
    <StyledWrapper
      className="calendar-input"
      $isRequired={isRequired}
      $isValid={isValid}
      onClick={React.useCallback(() => setIsOpen((prev) => !prev), [setIsOpen])}
    >
      <span className={`label ${size === 'medium' ? 'medium-label' : ''}`}>
        {formatDate(value) || placeholder}
      </span>
      <Calendar />
    </StyledWrapper>
  );
});

CalendarInput.propTypes = {
  isRequired: PropTypes.bool,
  isValid: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.object, // For moment objects
  ]),
  setIsOpen: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['small', 'medium']),
  dateSeparator: PropTypes.string,
};

export default CalendarInput;

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  padding: 1rem 1.6rem;
  border: solid 1px
    ${({ $isRequired, $isValid }) => ($isRequired && !$isValid ? '#F86060' : '#e9ebef')};
  background: #fff;
  .label {
    font-size: 1.3rem;
    ${({ $isRequired, $isValid }) => ($isRequired && !$isValid ? 'color: #f86060' : '')};
    .placeholder {
      font-weight: 300;
      ${({ $isRequired, $isValid }) =>
        $isRequired && !$isValid ? 'color: #f86060' : 'rgba(0, 0, 0, 0.5)'};
    }

    &.medium-label {
      line-height: 30px;
      align-content: center;
      display: flex;
      align-items: center;
    }
  }
  cursor: pointer;
  &:hover {
    border: solid 1px
      ${({ $isRequired, $isValid }) => ($isRequired && !$isValid ? '#f86060' : '#ccc')};
  }
  &:active {
    box-shadow: 0 0 0 0.1rem
      ${({ $isRequired, $isValid }) =>
        $isRequired && !$isValid ? '#F86060' : 'rgba(0, 0, 0, 0.25)'};
  }
`;
