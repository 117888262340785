import React from 'react';

import Button from 'components/new/Button';

import styles from './GenericEditForm.module.css';

interface GenericEditFormProps<T, E> {
  title: string;
  initialData: T;
  onSubmit: (data: T) => void;
  onCancel: () => void;
  isSubmitting?: boolean;
  useFormHook: (initialData: T) => {
    item: T;
    errors: E;
    handleChange: (field: string) => (value: string) => void;
    handleDateChange: (field: 'started_at' | 'ended_at') => (date: string | null) => void;
    validateForm: () => boolean;
    resetForm: () => void;
  };
  renderFields: (params: {
    data: T;
    errors: E;
    handleChange: (field: string) => (value: string) => void;
    handleDateChange: (field: 'started_at' | 'ended_at') => (date: string | null) => void;
    isSubmitting?: boolean;
  }) => React.ReactNode;
}

export const GenericEditForm = <T, E>({
  title,
  initialData,
  onSubmit,
  onCancel,
  isSubmitting = false,
  useFormHook,
  renderFields,
}: GenericEditFormProps<T, E>) => {
  const {
    item: data,
    errors,
    handleChange,
    handleDateChange,
    validateForm,
  } = useFormHook(initialData);

  const handleSubmit = () => {
    if (validateForm()) {
      onSubmit(data);
    }
  };

  return (
    <>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.wrapper}>
        {renderFields({ data, errors, handleChange, handleDateChange, isSubmitting })}
        <div className={styles.buttonsContainer}>
          <Button
            text={isSubmitting ? 'Updating...' : 'Update'}
            onClick={handleSubmit}
            variant="primary-purple"
            className={styles.addButton}
            disabled={isSubmitting}
          />
          <Button
            text="Cancel"
            onClick={onCancel}
            variant="secondary-purple"
            disabled={isSubmitting}
          />
        </div>
      </div>
    </>
  );
};
