import moment from 'moment';

import { useMultiItemForm } from 'hooks/patient/medications-and-supplements/creation/useMultiItemForm';

export const validateDateRange = (startDate: string, endDate: string): string | undefined => {
  if (startDate && endDate) {
    const start = moment(startDate);
    const end = moment(endDate);
    if (end.isBefore(start)) {
      return 'End date must be after start date';
    }
  }
  return undefined;
};

export const useGenericForm = <T>(
  initialData: T,
  validateItem: (item: T) => Partial<Record<keyof T, string>>,
) => {
  const formHook = useMultiItemForm<T>({ initialData, validateItem });
  return {
    ...formHook,
    items: formHook.items,
    hasMoreThanOne: formHook.hasMoreThanOneItem,
  };
};
