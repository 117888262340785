import Tippy from '@tippyjs/react';
import moment from 'moment';
import React from 'react';

import questionMarkIcon from 'assets/question.svg';
import ConnectedLabel from 'components/new/ConnectedLabel';

import styles from './index.module.css';
import { deviceInfo } from '../Accounts/config';
import { DeviceType, IDeviceProps } from '../interfaces';

export const DeviceCard: React.FC<IDeviceProps> = ({
  type,
  deviceFields,
  isConnected,
  isHealthyConnection,
  lastUpdated,
}) => {
  function renderConnectionBadge() {
    return <ConnectedLabel isConnected={isConnected} isHealthy={isHealthyConnection} />;
  }

  function renderLogo(type: DeviceType) {
    const logo = deviceInfo[type].logo;
    return logo ? <img src={logo} alt={type} /> : <span className={styles.deviceType}>{type}</span>;
  }

  const getTooltipText = (): string => {
    if (!isConnected) {
      return 'Device is not connected. No data is currently syncing.';
    }
    return isHealthyConnection
      ? 'Device is properly connected and syncing data.'
      : 'Device is still linked, but sync is not working. User needs to reconnect.';
  };

  const renderInfoIcon = () => (
    <Tippy content={getTooltipText()}>
      <img src={questionMarkIcon} alt="info-icon" />
    </Tippy>
  );

  const renderHeader = () => {
    return (
      <div className={styles.header}>
        {renderLogo(type)}
        <div className={styles.actions}>
          {lastUpdated && (
            <p className={styles.lastUpdated}>
              <span>Last updated&nbsp;&nbsp;</span>
              <span>{moment(lastUpdated).format('MMM D, YYYY')}</span>
            </p>
          )}
          <div className={styles.infoContainer}>{renderInfoIcon()}</div>
          {renderConnectionBadge()}
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className={styles.body}>
        <p>The following metrics are automatically synced and displayed on your dashboard:</p>
        <ul className={styles.fields}>
          {deviceFields.map((field) => (
            <li key={field.key}>{field.label}</li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <>
      <div className={styles.wrapper}>
        {renderHeader()}
        {renderBody()}
      </div>
    </>
  );
};
