import { N_A } from 'containers/patients/details/PatientSummary';

export const getFileNameFromUrl = (url: string): string => {
  if (!url || url === N_A) {
    return '';
  }
  const pathSegment = url.split('/').pop() || '';
  return pathSegment.split('?')[0];
};

export const extractDate = (date?: string) => (date ? date.split('T')[0] : '-');
