export enum DeviceType {
  WITHINGS = 'withings',
  FITBIT = 'fitbit',
  DEXCOM = 'dexcom',
}

export interface IDeviceField {
  key: string;
  label: string;
}

export interface IDeviceProps {
  type: DeviceType;
  deviceFields: IDeviceField[];
  isConnected: boolean;
  isHealthyConnection: boolean;
  lastUpdated: Date | null;
}

export interface IDevicesConfig {
  withings: IDeviceField[];
  fitbit: IDeviceField[];
  dexcom: IDeviceField[];
}

export interface DeviceStatus {
  device: DeviceType;
  connected: boolean;
  healthy: boolean;
  last_updated: Date | null;
}
