import React from 'react';
import styled from 'styled-components';

import { MediumText } from 'common/texts';

import Icon from '../common/Icon';

export default function MedicationHeader({ icon, medicationName, description }) {
  return (
    <StyledWrapper className="g-d-flex g-align-center">
      <Icon icon={icon} />
      <StyledTextContainer className="g-ml-15">
        <StyledName title={medicationName}>{medicationName}</StyledName>
        {description && (
          <StyledName title={description} className="description">
            {description}
          </StyledName>
        )}
      </StyledTextContainer>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  width: ${({ description }) => (description ? '40' : '37')}%;
  display: flex;
  align-items: center;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 1.5rem;
  }
`;

const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
`;

const StyledName = styled(MediumText)`
  word-break: break-all;
`;
