import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

import Spinner from 'common/loaders/Spinner';
import { RowPlate } from 'common/plate/plates';
import { PlateContent } from 'common/plate/styles';
import { RegularBigText } from 'common/texts';
import { PATIENTS, DETAILS, MEDICATIONS_AND_SUPPLEMENTS } from 'constants/routes';
import { MedicationsAndSupplementsHeader } from 'containers/patients/details/medications-and-supplements/components/Header';
import { EditSupplementForm } from 'containers/patients/details/medications-and-supplements/supplements/SupplementForm/EditSupplementForm';
import { SupplementData } from 'hooks/patient/medications-and-supplements/creation/useSupplementForm';
import { useUpdatePatientSupplement, useFetchSupplementById } from 'requests/patients/supplements';

export const EditSupplement = () => {
  const { patientId, supplementId } = useParams<{ patientId: string; supplementId: string }>();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const updateSupplement = useUpdatePatientSupplement();
  const { supplement, loading, fetchSupplementById } = useFetchSupplementById();

  React.useEffect(() => {
    if (supplementId) {
      fetchSupplementById(supplementId);
    }
  }, [supplementId, fetchSupplementById]);

  const handleSubmit = (formData: SupplementData) => {
    setIsSubmitting(true);
    updateSupplement(supplementId, formData, setIsSubmitting);
  };

  const handleCancel = () => {
    history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${MEDICATIONS_AND_SUPPLEMENTS}`);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <RowPlate>
      <MedicationsAndSupplementsHeader />
      <PlateContent>
        {!supplement ? (
          <RegularBigText>Supplement not found</RegularBigText>
        ) : (
          <EditSupplementForm
            initialSupplement={supplement}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            isSubmitting={isSubmitting}
          />
        )}
      </PlateContent>
    </RowPlate>
  );
};
