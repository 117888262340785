import moment from 'moment';
import React from 'react';

import { Input } from 'components/new/Input';
import { DatePickerField } from 'containers/patients/details/medications-and-supplements/components/DatePickerField';
import { MedicationData } from 'hooks/patient/medications-and-supplements/creation/useMedicationForm';

import styles from './MedicationForm.module.css';

interface MedicationFormFieldsProps {
  medication: MedicationData;
  errors: Partial<Record<string, string>>;
  handleChange: (field: keyof MedicationData) => (value: string) => void;
  handleDateChange: (field: 'started_at' | 'ended_at') => (date: string | null) => void;
  isSubmitting?: boolean;
}

export const MedicationFormFields: React.FC<MedicationFormFieldsProps> = ({
  medication,
  errors,
  handleChange,
  handleDateChange,
  isSubmitting,
}) => {
  return (
    <>
      <div className={styles.formRow}>
        <div className={styles.inputWrapper}>
          <Input
            label="MEDICATION NAME"
            placeholder="Type a medication name"
            value={medication.name}
            changeHandler={handleChange('name')}
            isRequired={true}
            error={errors.name}
            disabled={isSubmitting}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Input
            label="DOSAGE"
            placeholder=""
            value={medication.dosage}
            changeHandler={handleChange('dosage')}
            isRequired={true}
            error={errors.dosage}
            disabled={isSubmitting}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Input
            label="UNITS"
            placeholder=""
            value={medication.units}
            changeHandler={handleChange('units')}
            isRequired={true}
            error={errors.units}
            disabled={isSubmitting}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Input
            label="FORM"
            placeholder=""
            value={medication.form}
            changeHandler={handleChange('form')}
            disabled={isSubmitting}
          />
        </div>
        <div className={styles.dateInputWrapper}>
          <DatePickerField
            className={styles.dateInput}
            label="START DATE"
            value={medication.started_at}
            onChange={(date) => handleDateChange('started_at')(date)}
            minDate={null}
          />
        </div>
        <div className={styles.dateInputWrapper}>
          <DatePickerField
            className={styles.dateInput}
            label="END DATE"
            value={medication.ended_at}
            onChange={(date) => handleDateChange('ended_at')(date)}
            error={errors.ended_at}
            minDate={
              medication.started_at
                ? moment(medication.started_at).startOf('day').toDate()
                : undefined
            }
          />
        </div>
      </div>
      <div className={styles.sigInput}>
        <Input
          label="SIG"
          placeholder=""
          value={medication.signetur}
          changeHandler={handleChange('signetur')}
          disabled={isSubmitting}
        />
      </div>
    </>
  );
};
