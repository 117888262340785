import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import CustomCalendar from 'common/custom-calendar';
import { ErrorMessage } from 'components/new/ErrorMessage';

export const DatePickerField = ({
  className = '',
  label,
  value,
  validate = false,
  minDate,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (date) => {},
  position = 'left',
  size = 'medium',
  error = '',
  dateSeparator = '/',
}) => {
  const formatDate = (date) => {
    return date ? new Date(date).toISOString().split('T')[0] : null;
  };

  const [localDate, setLocalDate] = useState(() => {
    return formatDate(value);
  });

  useEffect(() => {
    const newDate = formatDate(value);
    setLocalDate(newDate);
  }, [value]);

  const handleDateChange = (date) => {
    const formattedDate = formatDate(date);
    setLocalDate(formattedDate);
    onChange(formattedDate);
  };

  return (
    <>
      <CustomCalendar
        className={className}
        label={label}
        isValid={(!validate || localDate) && !error}
        selectedDate={localDate}
        setSelectedDate={handleDateChange}
        minDate={minDate}
        position={position}
        size={size}
        dateSeparator={dateSeparator}
      />
      {error && <ErrorMessage message={error} />}
    </>
  );
};

DatePickerField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  validate: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  position: PropTypes.oneOf(['left', 'right']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  error: PropTypes.string,
  dateSeparator: PropTypes.string,
};
