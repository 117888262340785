import moment from 'moment/moment';
import React from 'react';

import { Input } from 'components/new/Input';
import { DatePickerField } from 'containers/patients/details/medications-and-supplements/components/DatePickerField';
import { SupplementData } from 'hooks/patient/medications-and-supplements/creation/useSupplementForm';

import styles from './SupplementForm.module.css';

interface SupplementFormFieldsProps {
  supplement: SupplementData;
  errors: Partial<Record<string, string>>;
  handleChange: (field: string) => (value: string) => void;
  handleDateChange: (field: 'started_at' | 'ended_at') => (date: string | null) => void;
  isSubmitting?: boolean;
}

export const SupplementFormFields: React.FC<SupplementFormFieldsProps> = ({
  supplement,
  errors,
  handleChange,
  handleDateChange,
  isSubmitting,
}) => {
  return (
    <>
      <div className={styles.formRow}>
        <div className={styles.inputWrapper}>
          <Input
            label="SUPPLEMENT NAME"
            placeholder="Type a supplement name"
            value={supplement.name}
            changeHandler={handleChange('name')}
            isRequired={true}
            error={errors.name}
            disabled={isSubmitting}
          />
        </div>

        <div className={styles.dateInputWrapper}>
          <DatePickerField
            className={styles.dateInput}
            label="START DATE"
            value={supplement.started_at}
            onChange={(date) => handleDateChange('started_at')(date)}
            minDate={null}
          />
        </div>

        <div className={styles.dateInputWrapper}>
          <DatePickerField
            className={styles.dateInput}
            label="END DATE"
            value={supplement.ended_at}
            onChange={(date) => handleDateChange('ended_at')(date)}
            error={errors.ended_at}
            minDate={
              supplement.started_at
                ? moment(supplement.started_at).startOf('day').toDate()
                : undefined
            }
          />
        </div>
      </div>

      <div className={styles.sigInput}>
        <Input
          label="SIG"
          placeholder=""
          value={supplement.signetur}
          changeHandler={handleChange('signetur')}
          disabled={isSubmitting}
        />
      </div>
    </>
  );
};
