import {
  useSupplementForm,
  SupplementData,
  initialSupplementData,
} from 'hooks/patient/medications-and-supplements/creation/useSupplementForm';
import {
  createSingleFormHook,
  UseMultipleItemsReturn,
} from 'hooks/patient/medications-and-supplements/edition/useSingleForm';

export const useSingleSupplementForm = createSingleFormHook<SupplementData>(
  useSupplementForm as () => UseMultipleItemsReturn<SupplementData>,
  initialSupplementData,
);
