import React from 'react';

import { GenericEditForm } from 'containers/patients/details/medications-and-supplements/GenericEditForm';
import { MedicationData } from 'hooks/patient/medications-and-supplements/creation/useMedicationForm';
import { useSingleMedicationForm } from 'hooks/patient/medications-and-supplements/edition/useSingleMedicationForm';

import { MedicationFormFields } from './MedicationFormFields';

interface EditMedicationFormProps {
  initialMedication: MedicationData;
  onSubmit: (medication: MedicationData) => void;
  onCancel: () => void;
  isSubmitting?: boolean;
}

export const EditMedicationForm: React.FC<EditMedicationFormProps> = ({
  initialMedication,
  onSubmit,
  onCancel,
  isSubmitting = false,
}) => {
  return (
    <GenericEditForm
      title="Edit Medication"
      initialData={initialMedication}
      onSubmit={onSubmit}
      onCancel={onCancel}
      isSubmitting={isSubmitting}
      useFormHook={useSingleMedicationForm}
      renderFields={({ data, errors, handleChange, handleDateChange, isSubmitting }) => (
        <MedicationFormFields
          medication={data}
          errors={errors}
          handleChange={handleChange}
          handleDateChange={handleDateChange}
          isSubmitting={isSubmitting}
        />
      )}
    />
  );
};
