import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Spinner from 'common/loaders/Spinner';
import Button from 'components/new/Button';
import SimpleTabSwitcher from 'components/new/SimpleTabSwitcher/SimpleTabSwitcher';
import DetailsTemplate from 'components/patients/DetailsTemplate';
import {
  MEDICATIONS_AND_SUPPLEMENTS,
  PATIENTS,
  DETAILS,
  MEDICATIONS,
  CREATE,
  SUPPLEMENTS,
  EDIT,
} from 'constants/routes';
import { MedicationsList } from 'containers/patients/details/medications-and-supplements/medications/MedicationsList';
import { SupplementsList } from 'containers/patients/details/medications-and-supplements/supplements/SupplementsList';
import {
  useFetchPatientMedications,
  useDeletePatientMedication,
} from 'requests/patients/medications';
import {
  useFetchPatientSupplements,
  useDeletePatientSupplement,
} from 'requests/patients/supplements';

import styles from './MedicationsAndSupplementsList.module.css';

export const MedicationsAndSupplementsList: React.FC = () => {
  const history = useHistory();
  const { patientId } = useParams<{ patientId: string }>();

  const [currentTab, setCurrentTab] = useState('medications');

  const tabs = [
    { label: 'Medications', value: 'medications' },
    { label: 'Supplements', value: 'supplements' },
  ];

  const {
    medications,
    loading: medicationsLoading,
    error: medicationsError,
    refetch: refetchMedications,
  } = useFetchPatientMedications();

  const {
    supplements,
    loading: supplementsLoading,
    error: supplementsError,
    refetch: refetchSupplements,
  } = useFetchPatientSupplements();

  const deleteMedication = useDeletePatientMedication();

  const deleteSupplement = useDeletePatientSupplement();
  const MEDICATIONS_AND_SUPPLEMENTS_PATH = `/${PATIENTS}/${DETAILS}/${patientId}/${MEDICATIONS_AND_SUPPLEMENTS}`;

  const navigateToCreateMedication = React.useCallback(() => {
    history.push(`${MEDICATIONS_AND_SUPPLEMENTS_PATH}/${MEDICATIONS}/${CREATE}`);
  }, [history, patientId]);

  const navigateToCreateSupplement = React.useCallback(() => {
    history.push(`${MEDICATIONS_AND_SUPPLEMENTS_PATH}/${SUPPLEMENTS}/${CREATE}`);
  }, [history, patientId]);

  const handleEditMedication = (id: number) => {
    history.push(`${MEDICATIONS_AND_SUPPLEMENTS_PATH}/${MEDICATIONS}/${id}/${EDIT}`);
  };

  const handleDeleteMedication = async (id: number) => {
    const success = await deleteMedication(id);
    if (success) {
      refetchMedications();
    }
  };

  const handleEditSupplement = (id: number) => {
    history.push(`${MEDICATIONS_AND_SUPPLEMENTS_PATH}/${SUPPLEMENTS}/${id}/${EDIT}`);
  };

  const handleDeleteSupplement = async (id: number) => {
    const success = await deleteSupplement(id);
    if (success) {
      refetchSupplements();
    }
  };

  if (medicationsLoading || supplementsLoading) {
    return (
      <DetailsTemplate currentTab={MEDICATIONS_AND_SUPPLEMENTS}>
        <div className={styles.loaderContainer}>
          <Spinner />
        </div>
      </DetailsTemplate>
    );
  }

  if (medicationsError || supplementsError) {
    return (
      <DetailsTemplate currentTab={MEDICATIONS_AND_SUPPLEMENTS}>
        <div className={styles.errorContainer}>
          <p>Error loading data. Please try again.</p>
          <Button
            onClick={() => {
              refetchMedications();
              refetchSupplements();
            }}
            text="Retry"
          />
        </div>
      </DetailsTemplate>
    );
  }

  const isMedicationsTabActive = currentTab === 'medications';
  return (
    <DetailsTemplate currentTab={MEDICATIONS_AND_SUPPLEMENTS}>
      <div className={styles.header}>
        <SimpleTabSwitcher tabs={tabs} currentTab={currentTab} onChange={setCurrentTab} />
        <div className={styles.buttonContainer}>
          {isMedicationsTabActive ? (
            <Button
              onClick={navigateToCreateMedication}
              disabled={false}
              className={styles.button}
              text="Add New Medication"
              size="medium"
            />
          ) : (
            <Button
              onClick={navigateToCreateSupplement}
              disabled={false}
              className={styles.button}
              text="Add New Supplement"
              size="medium"
            />
          )}
        </div>
      </div>
      <div className={styles.listsContainer}>
        {isMedicationsTabActive ? (
          <MedicationsList
            medications={medications}
            onEdit={handleEditMedication}
            onDelete={handleDeleteMedication}
          />
        ) : (
          <SupplementsList
            supplements={supplements}
            onEdit={handleEditSupplement}
            onDelete={handleDeleteSupplement}
          />
        )}
      </div>
    </DetailsTemplate>
  );
};
