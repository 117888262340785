import React from 'react';

import styles from './SimpleTabSwitcher.module.css';

type Tab = {
  label: string;
  value: string;
};

type SimpleTabSwitcherProps = {
  tabs: Tab[];
  currentTab: string;
  onChange: (value: string) => void;
};

const SimpleTabSwitcher: React.FC<SimpleTabSwitcherProps> = ({ tabs, currentTab, onChange }) => {
  return (
    <div className={styles.tabSwitcher}>
      {tabs.map((tab) => (
        <button
          key={tab.value}
          className={currentTab === tab.value ? styles.activeTab : ''}
          onClick={() => onChange(tab.value)}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};

export default SimpleTabSwitcher;
