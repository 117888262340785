import React, { useState } from 'react';

import { DeleteModal } from 'components/new/DeleteModal/DeleteModal';
import { MedSupplementItemCard } from 'containers/patients/details/medications-and-supplements/components/MedSupplementItemCard';
import styles from 'containers/patients/details/medications-and-supplements/medications/MedicationsList.module.css';
import { MedicationType } from 'containers/patients/details/medications-and-supplements/types';

interface MedicationsListProps {
  medications: MedicationType[];
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
}

export const MedicationsList: React.FC<MedicationsListProps> = ({
  medications,
  onEdit,
  onDelete,
}) => {
  const [medicationToDelete, setMedicationToDelete] = useState<number | null>(null);

  if (!medications?.length) {
    return (
      <div className={styles.sectionContainer}>
        <h2 className={styles.sectionTitle}>Medications</h2>
        <div className={styles.emptyState}>No medications found</div>
      </div>
    );
  }

  const handleDeleteClick = (id: number) => {
    setMedicationToDelete(id);
  };

  const handleConfirmDelete = () => {
    if (medicationToDelete !== null) {
      onDelete(medicationToDelete);
      setMedicationToDelete(null);
    }
  };

  const handleCancelDelete = () => {
    setMedicationToDelete(null);
  };

  return (
    <div className={styles.sectionContainer}>
      <div className={styles.listContainer}>
        {medications.map((medication) => (
          <MedSupplementItemCard
            key={medication.id}
            item={{
              id: medication.id,
              name: medication.name,
              dates: {
                startDate: medication.started_at,
                endDate: medication.ended_at,
              },
              dosage: medication.dosage,
              signetur: medication.signetur,
              units: medication.units,
            }}
            onEdit={() => onEdit(medication.id)}
            onDelete={() => handleDeleteClick(medication.id)}
          />
        ))}
      </div>
      {medicationToDelete !== null && (
        <DeleteModal
          itemType="Medication"
          description="Please confirm you want to delete this medication from the list"
          onDelete={handleConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}
    </div>
  );
};
