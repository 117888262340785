import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './index.module.css';

interface ConnectedLabelProps {
  isConnected: boolean;
  isHealthy?: boolean;
}

const ConnectedLabel: FC<ConnectedLabelProps> = ({ isConnected, isHealthy }) => {
  if (!isConnected) {
    return (
      <span className={classNames(styles.label, styles.disconnected)}>
        <span className={styles.icon}>✖️</span> Not connected
      </span>
    );
  }

  return isHealthy ? (
    <span className={classNames(styles.label, styles.connected)}>
      <ConnectedIcon className={classNames(styles.icon, styles.connected)} /> Connected
    </span>
  ) : (
    <span className={classNames(styles.label, styles.unhealthy)}>
      <UnhealthyIcon className={styles.icon} /> Reconnect
    </span>
  );
};

const ConnectedIcon = ({ className }: { className: string }) => {
  return (
    <svg
      className={className}
      width="10"
      height="8"
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 4.00004L3.66667 6.66671L9 1.33337"
        stroke="#14532D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const UnhealthyIcon = ({ className }: { className: string }) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8641 10.7778L12.6991 9.62962C13.2169 9.49382 13.6376 9.23148 13.9612 8.84259C14.2848 8.4537 14.4466 7.99999 14.4466 7.48148C14.4466 6.86419 14.2201 6.3395 13.767 5.9074C13.314 5.4753 12.7638 5.25925 12.1165 5.25925H9.00975V3.77777H12.1165C13.191 3.77777 14.1068 4.13888 14.8641 4.86111C15.6214 5.58333 16 6.45678 16 7.48148C16 8.18518 15.8091 8.83333 15.4272 9.42592C15.0454 10.0185 14.5243 10.4691 13.8641 10.7778ZM11.2233 8.22222L9.66995 6.74074H11.3398V8.22222H11.2233ZM12.3334 13.3754L0.666707 2.37037L1.75409 1.33333L13.4208 12.3384L12.3334 13.3754ZM7.45636 11.1852H4.34956C3.27513 11.1852 2.35927 10.8241 1.60199 10.1018C0.844705 9.37962 0.466064 8.50617 0.466064 7.48148C0.466064 6.62962 0.737909 5.87037 1.2816 5.2037C1.82529 4.53703 2.52432 4.09876 3.37869 3.88888L4.81558 5.25925H4.34956C3.70231 5.25925 3.15215 5.4753 2.69907 5.9074C2.246 6.3395 2.01946 6.86419 2.01946 7.48148C2.01946 8.09876 2.246 8.62345 2.69907 9.05555C3.15215 9.48765 3.70231 9.7037 4.34956 9.7037H7.45636V11.1852ZM5.12626 8.22222V6.74074H6.38839L7.92237 8.22222H5.12626Z"
        fill="#92400E"
      />
    </svg>
  );
};

export default ConnectedLabel;
