import moment from 'moment';
import React, { useCallback, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';

import CustomButton from 'common/CustomButton';
import StyledCalendar from 'common/styledCalendar';

export default React.memo(function CalendarWindow({
  minDate,
  setIsOpen,
  selectedDate,
  setSelectedDate,
}) {
  const [date, setDate] = React.useState(selectedDate ? moment(selectedDate).toDate() : new Date());

  const calendarRef = useRef(null);

  const chooseHandler = useCallback(() => {
    setSelectedDate(date);
    setIsOpen(false);
  }, [date, setSelectedDate, setIsOpen]);

  useLayoutEffect(() => {
    const calendarElement = calendarRef.current;
    if (calendarElement) {
      const rect = calendarElement.getBoundingClientRect();
      const spaceOnRight = window.innerWidth - rect.right;
      const spaceOnLeft = rect.left;

      if (spaceOnRight > spaceOnLeft) {
        calendarElement.style.left = '0';
        calendarElement.style.right = 'auto';
      } else {
        calendarElement.style.left = 'auto';
        calendarElement.style.right = '0';
      }
    }
  }, []);

  return (
    <StyledWrapper ref={calendarRef} className="calendar-window">
      <StyledCalendar
        nextLabel=">>"
        prevLabel="<<"
        minDate={minDate}
        minDetail="month"
        MonthView
        onChange={(date) => setDate(date)}
        value={date}
      />
      <CustomButton className="choose-button" clickHandler={chooseHandler}>
        Choose
      </CustomButton>
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  width: 40rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #fff;
  padding: 2rem 3.5rem;
  border: solid 1px #e9ebef;
  box-shadow: 0 20px 60px 0 rgba(163, 171, 186, 0.4);
  z-index: 10;

  .checkbox-container {
    display: flex;
    flex-wrap: wrap;
    .calendar-checkbox {
      width: 33%;
    }
  }
  .choose-button {
    margin-top: 1rem;
    width: 11rem;
    font-size: 1.3rem;
    padding: 1rem 0;
  }
`;
